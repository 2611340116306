import React from "react";
import "../../css/style.css";
import logo from "../../img/logo.png";
import icono_user from "../../img/icono-user.png";
import icono_whats from "../../img/icono-whats.png";
import icono_userm from "../../img/icono-user-azul.png";
import icono_whatsm from "../../img/icono-whats-azul.png";
import logo_pabs2 from "../../img/logo_pabs.png";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import icono_mujer from "../../img/icono_mujer.png";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",

  maxHeight: "500px",
  background: "radial-gradient(circle at top left, #005593, #36c9d0)",
  boxShadow: 29,
  overflowY: "auto",
};

export const Navbar = () => {
  const [open2, setOpen2] = React.useState(false);
  const handleClose = () => setOpen2(false);
  return (
    <div>
      <Modal
        keepMounted
        open={open2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* //<ScrollView width="100%" height="100%"> */}
        <Grid container spacing={2} sx={style}>
          <div className="modal-content">
            <div className="d-flex justify-content-end p-1  ">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center mb-3">
                <img
                  src={logo}
                  alt="PRADO'S"
                  width="220"
                  className="img-fluid"
                />
              </div>
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 p-3" style={{ background: "#ff00006E" }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={logo_pabs2}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Tengo título PABS{" "}
                    </h4>
                    <h5 className="rLight mt-4">
                      Tu crédito ya está <br />
                      Pre-autorizado
                    </h5>

                    <div className="mt-4">
                      <a
                        href="https://app.percapita.mx/Titulo?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                        className="btn btn-primary btn-conoce-azul"
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                    <div className="mt-4">
                      <p className="rLight mt-4">
                        ¿Qué es un Título PABS? <br />
                        <a
                          href="https://www.pabsmr.org/que-es-un-titulo-pabs/"
                          target="_blank"
                          style={{ color: "white" }}
                        >
                          Click aqui
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-3" style={{ background: "#ffff0040" }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={icono_mujer}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Quiero un <br /> crédito personal{" "}
                    </h4>
                    <h5 className="rLight mt-4">
                      Tu crédito en <br />
                      48 horas*
                    </h5>

                    <div className="mt-4">
                      <a
                        href="https://app.percapita.mx/Personal?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                        className="btn btn-primary btn-conoce-azul"
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Modal>
      {/* ESCRITORIO NAVBAR */}
      <div class="d-none d-sm-none d-md-block p-0 m-0 ">
        <nav class="navbar navbar-expand-lg navbar-light p-0 m-0 nav-pc">
          <div class="container-fluid p-0 m-0">
            <a class="navbar-brand navbar-brand-left" href="/">
              <img src={logo} alt="PRADO'S" class="img-fluid" />
            </a>
            <h6 className="text-number">
              Llámanos:{" "}
              <a
                href="tel:+523336168059"
                className="a_none_style text-blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                33 3616-8059
              </a>
            </h6>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                  <a class="nav-link nav-text" href="/nosotros">
                    {" "}
                    <h6 class="text-nav">Sobre nosotros</h6>
                  </a>
                </li>
                {/* <li class="nav-item">
                                    <a class="nav-link nav-text" href="/financiamiento"> <h6 class="text-nav">Financiamiento</h6></a>
                                </li> */}
                <li class="nav-item">
                  {/* <a  class="nav-link nav-text" href="https://app.percapita.mx/?from=Titulo"> <h6 class="text-nav">Crédito PABS</h6></a> */}
                  <a
                    class="nav-link nav-text"
                    href="https://app.percapita.mx/Titulo?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                  >
                    {" "}
                    <h6 class="text-nav">Crédito PABS</h6>
                  </a>
                </li>
                <li class="nav-item">
                  {/* <a  class="nav-link nav-text" href="https://app.percapita.mx/?from=Titulo"> <h6 class="text-nav">Crédito PABS</h6></a> */}
                  <a
                    class="nav-link nav-text"
                    href="https://percapita.mx/Credito-personal"
                  >
                    {" "}
                    <h6 class="text-nav">Crédito Personal</h6>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link nav-text" href="/blog">
                    {" "}
                    <h6 class="text-nav">Blog</h6>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link nav-image"
                    href="https://app.percapita.mx/login"
                  >
                    <img src={icono_user} alt="PRADO'S" class="img-fluid" />
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link nav-image"
                    href="https://api.whatsapp.com/send?phone=523315206918"
                    target="_blank"
                  >
                    <img src={icono_whats} alt="PRADO'S" class="img-fluid" />
                  </a>
                </li>
                <li class="nav-item nav-credit text-center">
                  <a
                    class="nav-link nav-text "
                    href="#"
                    onClick={() => setOpen2(true)}
                  >
                    {" "}
                    <h6 class="text-credit">Solicitar crédito</h6>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/* SMARTPHONE NAVBAR */}
      <div class="d-block d-sm-block d-md-none p-0 m-0 ">
        <nav class="navbar navbar-expand-lg navbar-light p-0 m-0 ">
          <div class="container-fluid p-0 m-0 ">
            <a class="navbar-brand" href="/">
              <img src={logo} alt="PRADO'S" class="img-fluid" />
            </a>
            <a class="nav-image-movil" href="https://app.percapita.mx/login">
              <img src={icono_userm} alt="PRADO'S" class="img-fluid" />
            </a>
            <a
              class="nav-image-movil"
              href="https://api.whatsapp.com/send?phone=523315206918"
            >
              <img src={icono_whatsm} alt="PRADO'S" class="img-fluid" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                  <a class="nav-link nav-text" href="/nosotros">
                    {" "}
                    <h6 class="text-nav">Sobre nosotros</h6>
                  </a>
                </li>
                {/* <li class="nav-item">
                                    <a class="nav-link nav-text" href="#"> <h6 class="text-nav">Financiamiento</h6></a>
                                </li> */}
                <li class="nav-item">
                  {/* <a class="nav-link nav-text" href="https://app.percapita.mx/?from=Titulo"> <h6 class="text-nav">Crédito PABS</h6></a> */}
                  <a
                    class="nav-link nav-text"
                    href="https://app.percapita.mx/Titulo?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                  >
                    {" "}
                    <h6 class="text-nav">Crédito PABS</h6>
                  </a>
                </li>
                
                <li class="nav-item">
                  {/* <a  class="nav-link nav-text" href="https://app.percapita.mx/?from=Titulo"> <h6 class="text-nav">Crédito PABS</h6></a> */}
                  <a
                    class="nav-link nav-text"
                    href="https://percapita.mx/Credito-personal"
                  >
                    {" "}
                    <h6 class="text-nav">Crédito Personal</h6>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link nav-text" href="/blog">
                    {" "}
                    <h6 class="text-nav">Blog</h6>
                  </a>
                </li>
                <li class="nav-item nav-credit-movil">
                  <a
                    class="nav-link nav-text "
                    href="#"
                    onClick={() => setOpen2(true)}
                  >
                    {" "}
                    <h6 class="text-credit">Solicitar crédito</h6>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
